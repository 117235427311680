@use 'sass:color';
@use '@material/theme' with (
  $primary: #70a288,
  $secondary: #bf9f00,
  $background: #e8e8e8,
  $on-primary: #f7f7f7
);

@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/drawer';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/slider/styles' as slider-styles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;

$mobile-width-max: 599px;
$tablet-width-min: 600px;
$tablet-width-max: 979px;
$danger: #b00020;

div h1 {
  line-height: 2.5rem !important;
}

div h2 {
  line-height: 2rem !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Open Sans', sans-serif;
  color: black;
}

a,
div,
label,
li,
p,
span,
td,
th,
.mdc-data-table__cell {
  @include typography.typography(body1);
  @media (min-width: $tablet-width-min) {
    font-size: 20px;
  }
}

body {
  margin: auto;
  @media (max-width: $tablet-width-min) {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

#body-content {
  padding: 0 20px;
}

.display-none {
  display: none;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 32px;

  @media (max-width: $tablet-width-max) {
    padding-top: 28px;
  }
}

$top-app-bar-gradient: linear-gradient(90deg, theme.$primary, #d5cad6);

.mdc-top-app-bar {
  background: theme.$primary;
}

.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: theme.$on-primary;
}

#topnav {
  @media (max-width: $tablet-width-max) {
    display: none;
  }
}

#mobilenav {
  @media (min-width: $tablet-width-max) {
    display: none;
  }
}

.mdc-banner {
  .mdc-button {
    @include button.ink-color(theme.$primary);
  }
}

// mobile styles
@media (max-width: $tablet-width-max) {
  header {
    left: 0;
  }

  .mobilebutton {
    display: flex;
    padding: 20px 0px;
    margin: 20px 10px 20px 10px;
    border: solid 1px;
  }

  ul,
  ol {
    padding-inline-start: 0;
  }

  li {
    margin-top: 10px;
    ul,
    ol {
      padding-inline-start: 25px;
    }
  }
  .accordion > * {
    ul,
    ol {
      padding-left: 15px;
    }
  }
}

.container {
  margin-left: 10%;
  margin-right: 10%;
}

.badge {
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: 600;
  padding: 6px 6px 4px 7px;
  height: 12px;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 18%);
  display: inline-block;
  min-width: 10px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
  background-color: #777;

  &.badge-roundless {
    border-radius: 0 !important;
  }

  &.badge-success {
    background-color: #d5cad6;
    background-image: none;
    color: black;
  }
}

.mdc-card {
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

#dashboard-page {
  h1 {
    line-height: 2.25rem !important;
  }
  h1,
  h2 {
    font-size: 2.25rem;
    text-align: center;
    margin-top: 1rem;

    #chosen-date {
      font-size: inherit;
      font-weight: inherit;
    }
  }
  h3 {
    font-size: 1.75rem;
    text-align: center;
    line-height: 2rem;
    margin: 1.75rem auto;
  }

  .weekly-exercise-portlet {
    padding-top: 1px;

    .mdc-card {
      box-shadow: none;
    }

    #weekly-progress {
      padding-top: 0;
    }
  }

  .portlet-title {
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    display: flex;

    .actions {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      margin-left: 1.5rem;
      margin-right: 0.5rem;

      :first-child {
        margin-right: 0.25rem;
      }
    }
  }

  h4 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .no-week-achievement {
    background-color: #ccc;
    border: 2px solid #aaa;
    padding: 0.5rem 0.75rem;
    color: #fff;

    span {
      font-weight: bold;
    }
  }

  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    h1,
    h2 {
      font-size: 1.85rem;
      text-align: center;
      margin: 0.25rem auto;

      #chosen-date {
        font-size: inherit;
        font-weight: inherit;
      }
    }
    h3 {
      font-size: 1.5rem;
      text-align: center;
      margin: 0.75rem auto;
    }

    h4 {
      font-size: 1.25rem;
      text-align: center;
      margin: 0.25rem auto;
    }
  }

  .minutes-and-exercise-container {
    #walking-log {
      display: flex;
      flex-direction: column;
      align-items: center;

      #minutesWalkedChart {
        width: 100%;
      }

      .edit-minutes-walked-buttons {
        display: flex;
        justify-content: center;

        :last-child {
          margin-left: 0.25rem;
        }
      }
    }
    @media (min-width: $tablet-width-max) {
      display: flex;
      width: 100%;
      justify-content: center;

      .portlet {
        margin: 0 0.25rem 20px 0.25rem;
        width: 50%;
      }

      #walking-log {
        width: 30%;
      }
    }
  }
}

#dashboard-header {
  width: 50%;
  margin-top: 1em;
  text-align: center;
  h1 {
    margin: auto;
  }
}

.portlet.participant-choice-portlet {
  max-width: 33%;
  margin-left: 0;

  @media (max-width: $tablet-width-max) {
    max-width: 45%;
  }
}

#participant-choice {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

#login {
  max-width: 500px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  margin: auto;

  label {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    .field_with_errors {
      width: 100%;
    }
  }

  select {
    margin-top: 10px;
  }

  .date-input {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    width: 250px;

    input {
      border: none;
      border-bottom: 1px solid #666;
    }
    input[type='date'] {
      @include typography.typography(body1);
    }
  }

  .mdc-select {
    margin-top: 0.5em;
  }

  .mdc-button--raised.cancel {
    background-color: $danger;
  }
}

#date-selector {
  width: 33%;
}

.hidden {
  display: none;
}

.portlet {
  padding: 12px 20px 15px 20px;
  background-color: #fff;
  border-radius: 4px !important;
  border: 0 !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 22%);
  width: 82.5%;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  @media (max-width: $tablet-width-max) {
    width: 90%;
  }
  @media (max-width: $mobile-width-max) {
    width: 98%;
    margin-left: 0;
    padding: 12px 10px 15px 10px;
  }

  .portlet-body {
    padding-top: 5px;
    border-radius: 0px 0px 4px 4px !important;
    border: 0 !important;
    clear: both;

    .slim-scroll {
      position: relative;
      overflow-y: hidden;
      width: auto;
      height: 353px;

      .scroller {
        height: 353px;
        overflow-y: scroll;
        width: auto;
        padding: 0px;
        margin: 0px;
        padding-right: 12px;

        // scroll to bottom of content
        flex: 1 1 auto;
        display: flex;
        flex-direction: column-reverse;

        .chats {
          margin: 0;
          padding: 0;
          margin-top: -15px;
          padding-left: 10px;

          li {
            list-style: none;
            padding: 5px 0;
            margin: 10px auto;
            font-size: 16px;

            .message {
              display: block;
              padding: 5px;
              position: relative;
              word-wrap: break-word;
              font-size: var(--mdc-typography-subtitle1-font-size, 1rem);

              .body {
                display: block;
                font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
              }

              .datetime {
                color: #ad4a4a;
                font-size: 13px;
                font-weight: 400;
              }
            }

            .in .message {
              text-align: left;
              border-left: 2px solid #1bbc9b;
              margin-left: 65px;
              background: #fafafa;

              .arrow {
                display: block;
                position: absolute;
                top: 5px;
                left: -8px;
                width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 8px solid #1bbc9b;
              }
            }

            .out .message {
              border-right: 2px solid #f3565d;
              margin-right: 65px;
              background: #fafafa;
              text-align: right;

              .arrow {
                display: block;
                position: absolute;
                top: 5px;
                right: -8px;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 8px solid #f3565d;
              }

              .datetime {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .message-sending {
    display: flex;
    align-items: center;

    label {
      flex: 1;
    }

    button {
      height: 56px;
      box-shadow: none;
      border-radius: 0 5px 0 0;
    }
  }
}

.portlet-columns {
  display: flex;
  justify-content: space-around;

  .portlet {
    margin-left: 0;
  }
}

#totalExerciseChart {
  overflow: unset !important;
}

/*
 * Form inputs
 */
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: initial;
}

// tables
.mdc-data-table.admin-table {
  width: 100%;

  .mdc-data-table__header-cell {
    font-size: var(--mdc-typography-subtitle2-font-size, 1.15rem);
    font-weight: bold;
  }

  .mdc-data-table__cell {
    font-size: var(--mdc-typography-body2-font-size, 1.1rem);
  }

  a {
    font-size: inherit;
  }

  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    .mdc-data-table__header-cell {
      font-size: 14px;
    }

    .mdc-data-table__cell {
      font-size: 12px;
    }

    a {
      font-size: inherit;
    }
  }
}

.new-admin-page,
.edit-admin-page,
.new-participant-page,
.edit-participant-page,
.edit-participant-threshold-page {
  max-width: 750px;
  margin: auto;
}

.subtext {
  font-size: 0.85rem;
}

.select-input-mb {
  margin-bottom: 0.5em;
}

.edit-participant-goals-headline,
.participant-coach-chat-headline {
  max-width: 86%;
  margin: 1.5rem auto;

  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    max-width: 97%;
  }
}

.participant-tracking-table {
  width: 100%;
  margin: 1rem auto;
  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    width: 100%;
    margin: 1rem 0;
  }
  @media (max-width: 1065px) and (min-width: $tablet-width-max) {
    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
      padding: 0 12px 0 12px;
    }
  }
}

.participant-tracking-table-head tr th {
  text-align: center;
  font-weight: bold;
  white-space: normal;
  font-size: var(--mdc-typography-subtitle2-font-size, 1.15rem);
  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    font-size: 14px;
    padding: 0.25rem;
  }
}

.participant-tracking-table-body td {
  text-align: center;
  font-size: var(--mdc-typography-body2-font-size, 1.1rem);
  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    font-size: 12px;
    padding: 0.35rem;

    .fa-solid {
      font-size: large;
    }
  }
}

.admin-chat-participant-list {
  margin-bottom: 2rem;
  border: 1px solid black;
  width: 100%;
}

.admin-coach-chat-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .new-messages {
    max-width: 33%;
  }

  @media (max-width: $tablet-width-max) {
    .new-messages {
      max-width: 45%;
      margin-right: 0;
      .portlet-title {
        overflow: hidden;
      }
    }
    #messages-portlet {
      max-width: 45%;
      margin-right: 0;
      .mdc-floating-label {
        font-size: 12px;
      }
    }
  }
}

.participant-search {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  max-height: 2em;
}

.new-messages {
  .participant-search {
    justify-content: flex-start;
    .participant-search-bar {
      margin-left: 0;
    }
  }
}

.participant-index-search {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 0.5rem auto;
  max-height: 2em;
  align-items: center;

  @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
    width: 100%;
    margin: 0.5rem 0;
  }
}

.participant-search-bar {
  background-color: rgb(245, 245, 245);
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-left: 1rem;
}

.participant-search-bar input {
  margin-bottom: 0.5rem;
  background-color: inherit;
  border: none;
}

.plain-text-input {
  background-color: whitesmoke;
  border: none;
  border-bottom: 1px solid #999;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  height: 56px;
  padding: 0 16px;
}
// Participant goals page styling
.select-week-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  h2:first-of-type {
    padding-right: 0.5rem;
  }
  h2:last-of-type {
    padding-left: 0.5rem;
  }
}

.week-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.week-select-input {
  margin: 0 1rem;
  height: 2rem;
  width: 4rem;
  font-size: inherit;
  text-align: center;
  @media (max-width: $tablet-width-max) {
    border-width: thin;
    border-radius: 3px;
  }
  @media (max-width: 1070px) and (min-width: $tablet-width-max) {
    margin: 0 0.25rem;
  }
}
// Hides number select arrows on Chrome, Safari, Edge, Opera
input.week-select-input::-webkit-outer-spin-button,
input.week-select-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Hides number select arrows on firefox
input[type='number'].week-select-input {
  -moz-appearance: textfield;
}

.mdc-button--raised:not(:disabled).decrement-button {
  background-color: #ad4a4a;
}

.goals-table {
  width: 100%;

  .mdc-data-table__header-cell {
    font-size: var(--mdc-typography-subtitle2-font-size, 1.15rem);
  }

  label {
    font-size: var(--mdc-typography-body1-font-size, 1.1rem);
  }
}

.edit-user-threshold-table {
  display: flex;
  margin: 2rem auto;
  max-width: 750px;

  .mdc-data-table__header-cell {
    font-size: var(--mdc-typography-subtitle2-font-size, 1.15rem);
    white-space: normal;
  }

  .mdc-data-table__cell {
    font-size: var(--mdc-typography-subtitle2-font-size, 1.1rem);
  }
}

.minutes-input .mdc-text-field__input {
  width: 6rem;
  @media (max-width: $tablet-width-max) {
    width: 3rem;
  }
}

.date-label {
  @media (max-width: $tablet-width-max) {
    font-size: 15px;
  }
}

.start-time-input,
.end-time-input {
  display: flex;
  flex-direction: row;

  .time-label {
    width: 33%;
    padding-top: 40px;
  }

  .stacked {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .mdc-button {
      background-color: unset;
      .mdc-button__icon {
        color: gray;
      }
    }
  }

  /*--------- CSS FOR AM/PM TOGGLE --------*/
  .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e0e0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #c2c2c2;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }

  .ampm-toggle {
    padding-top: 40px;
  }

  .pm {
    display: none;
  }

  .am,
  .pm {
    color: #666666;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 55%;
    font-size: 14px;
    font-family: Verdana, sans-serif;
  }

  .pm {
    color: white;
    left: 45%;
  }

  input:checked + .slider .pm {
    display: block;
  }

  input:checked + .slider .am {
    display: none;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  /*--------- END --------*/
}

.end-time-input {
  .time-label {
    padding-top: 60px;
  }
  .ampm-toggle {
    padding-top: 60px;
  }
}

.weekly-progress-table {
  white-space: initial;
  width: 100%;

  th.mdc-data-table__header-cell {
    font-size: 18px;
    padding: 5px;
    text-align: center;

    @media (min-width: $tablet-width-max) and (max-width: 1170px) {
      font-size: 15px;
    }
    @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
      font-size: 13px;
    }
  }

  th.mdc-data-table__header-cell:first-of-type {
    @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
      width: 11%;
    }
  }

  td.mdc-data-table__cell {
    font-size: 16.5px;
    padding: 5px;
    width: 15%;
    text-align: center;
    @media (max-width: $tablet-width-max) and (min-width: $tablet-width-min) {
      font-size: 11px;
      width: 5%;
      line-height: 1.25;
    }
  }
}

#total-exercise-chart {
  h3 {
    margin: auto;
  }
  .container {
    text-align: center;
    margin: auto;
  }
  .mdc-button {
    --mdc-theme-primary: #ffffff;
    --mdc-theme-on-primary: #8696a7;

    @include button.ink-color(#8696a7);
  }

  button:disabled,
  button[disabled] {
    --mdc-theme-primary: #ffffff;
    --mdc-theme-on-primary: #8696a7;

    @include button.ink-color(#8696a7);
  }
}

.dp-selected {
  background: #fff !important;
  color: #111 !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: black;
}

#login {
  .mdc-text-field {
    align-items: unset;
  }
}
